<template >
  <div >
    <h5 v-if="createAfterTableData.length !== 0" >
      {{ $t('key1003663') }}{{ createAfterTableData.length }}{{ $t('key1003664') }} </h5 >
    <Table border :columns="columns" :data="createAfterTableData" ></Table >
  </div >
</template >
<script >
import { alias1bddc4d174174d47a6dfb0437dace856 } from '@/customFolder/customVueAlias.js';

export default {
  props: ['data', 'createAfterTableData'],
  data () {
    return {
      columns: [
        {
          type: 'index',
          width: 60,
          align: 'center'
        }, {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1003665'),
          key: 'pickingGoodsNo'
        }, {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1002456'),
          key: 'type'
        }, {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1002407'),
          key: 'warehouseName'
        }
      ]
    };
  },
  created () {},
  computed: {},
  destroyed () {
    this.$parent.$parent.createAfterTableData = [];
  }
};
</script >

<style ></style >
